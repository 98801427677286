<template>
  <div>
    <div class="pititle">
      <div class="zhidingsearch">
        <el-form>
          <div class="litop">
            <el-row class="litop_title">
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="文件名称">
                    <el-input
                      v-model="name"
                      placeholder="请输入您的标准名称"
                      clearable
                      @keyup.enter.native="search()"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="所属阶段">
                    <el-select v-model="biaozhunType" placeholder="全部">
                      <el-option label="初稿审查" value="初稿审查"></el-option>
                      <el-option label="征求意见稿" value="征求意见稿"></el-option>
                      <el-option label="送审稿审查" value="送审稿审查"></el-option>
                      <el-option label="报批" value="报批"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple rightbtns">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="info" @click="clearWord">清空</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="piconetn">
        <div style="margin-bottom: 10px; display: flex; flex-direction: row-reverse">
          <el-button type="primary" @click="uploadEvent()">上传</el-button>
          <!-- <el-button type="success" style="height:45px;margin-right:11px;">批量下载</el-button> -->
        </div>
        <!-- 表格 -->
        <div class="pitable">
          <el-table
            :data="tableData"
            :row-class-name="tabRowClassName"
            style="width: 100%"
            :header-cell-style="{ background: '#3082e2', color: '#FFF' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              width="150px"
              align="center"
            ></el-table-column>
            <el-table-column label="文件名称" prop="name" align="center">
              <template slot-scope="{ row }">
                <div style="color: #409eff; cursor: pointer" @click="zhanshi(row)">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="所属阶段" align="center" width="150px">
              <template slot-scope="{ row }">
                <span>{{ row.updated_at | formatDate }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150px">
              <template slot-scope="{ row }">
                <el-button
                  type="success"
                  @click="download(row)"
                  size="mini"
                  icon="el-icon-download"
                ></el-button>
                <el-button
                  type="danger"
                  @click="del(row.id)"
                  size="mini"
                  icon="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              layout="prev, pager, next"
              :total="totalCount"
              :page-size="limit"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="政策文件上传" :visible.sync="dialogVisible" width="30%">
      <el-form ref="fileform" :model="form" label-width="80px">
        <el-form-item label="文件名称">
          <div class="wenjian">
            <div class="shangchuan">
              <el-input v-model="form.filename"></el-input>
            </div>
            <div class="shwj">
              <el-upload
                :data="{
                  token: $store.state.token,
                }"
                :file-list="materImgList"
                :before-upload="preUpload"
                :on-success="
                  (response, file, fileList) =>
                    changeMaterImg(1, response, file, fileList)
                "
                :show-file-list="false"
                class="upload-demo"
                :http-request="uploadFile"
                action=""
              >
                <el-button size="small" type="primary" style="height: 40px"
                  >上传文件
                </el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="所属阶段">
          <div class="shangchuan">
            <el-input v-model="form.status" placeholder="请输入所属阶段"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancleEvent()">取 消</el-button>
        <el-button type="primary" @click="addmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="showFileName"
      :visible.sync="dialogfiles"
      width="50%"
      height="60vh"
    >
      <iframe
        frameborder="0"
        class="iframe"
        :src="showFileSrc"
        style="width: 100%; height: 100%"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
import { Upload } from "element-ui";
import {
  savePolicyFile,
  enterpriseUpload,
  policyDel,
  getPolicyList,
} from "../../../../api/enterprise";
import axios from "axios";
let docx = require("docx-preview");
export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      //选中的表格数据
      selectedProjects: [],
      dialogVisible: false,
      dialogfiles: false,
      showFileSrc: "",
      showFileName: "",
      form: {
        filename: "",
        status: "",
      },
      fileform: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      status: "",
      token: "",
      firstUnit: "",
      name: "",
      zhidingType: "",
      biaozhunType: "",
      materImgList: [],
      multipleSelection: [],
      fileName: "",
      fileUrl: "",
    };
  },
  mounted() {
    // this.getList();
    this.getToken();
  },
  methods: {
    zhanshi(row) {
      console.log("2222");
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(row.url);
    },
    viewFile(src, filename) {
      this.showFileSrc = src;
      this.showFileName = filename;
      console.log("this.showFileName", this.showFileName);
      if (this.showFileSrc.lastIndexOf(".") != -1) {
        // if (
        //   this.showFileSrc.substring(this.showFileSrc.lastIndexOf("."))?.indexOf("doc") !=
        //   -1
        // )
        if (
          this.showFileSrc !== null &&
          this.showFileSrc !== undefined &&
          this.showFileSrc.substring(this.showFileSrc.lastIndexOf(".")) !== null &&
          this.showFileSrc.substring(this.showFileSrc.lastIndexOf(".")) !== undefined &&
          this.showFileSrc.substring(this.showFileSrc.lastIndexOf(".")).indexOf("doc") !==
            -1
        ) {
          this.showFileType = 1;
          this.$nextTick(function () {
            axios({
              method: "get",
              responseType: "blob", // 设置响应文件格式
              url: src,
              name: filename,
            }).then(({ data }) => {
              docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
            });
          });
        } else {
          this.showFileType = 2;
        }
      } else {
        this.showFileType = "";
      }
    },
    uploadEvent() {
      this.dialogVisible = true;
      this.form.filename = "";
      this.form.status = "";
    },
    //上传文件
    uploadFile(file) {
      const formData = new FormData();
      this.form.filename = file.file.name;
      // 向 formData 对象中添加文件
      formData.append("file", file.file);
      enterpriseUpload(formData)
        .then((res) => {
          this.fileName = res.data.name;
          this.fileUrl = res.data.url;
          // console.log("上传状态", res);
          // this.dialogVisible = false;
          this.$message({
            message: "上传成功",
            type: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    cancleEvent() {
      this.dialogVisible = false;
      this.form.filename = "";
      this.form.status = "";
    },
    addmit() {
      if (this.form.filename == "") {
        this.$message({
          message: "文件名称不能为空",
          type: "warning",
        });
        return false;
      } else if (this.form.status == "") {
        this.$message({
          message: "所属阶段不能为空",
          type: "warning",
        });
        return false;
      }

      let params = {
        // sid: "1",
        // files: this.materImgList,
        name: this.fileName,
        url: this.fileUrl,
        token: this.$store.state.token,
        status: this.form.status,
      };
      savePolicyFile(params)
        .then((res) => {
          console.log("上传", res);
          this.dialogVisible = false;
          this.$message({
            message: "文件提交成功",
            type: "success",
          });
          //回调列表
          this.getList();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    changeMaterImg(index, response, file, fileList) {
      this.materImgList.push(response.data);
      // this.form.filename = this.materImgList.map((item) => item.name).join(",");
      // console.log('打印文件名',this.form.filename)
    },
    preUpload() {
      // console.log(file,'文件')
    },
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      console.log(this.$store.state);
      this.getList();
    },
    getList() {
      // console.log('打印筛选的值', this.biaozhunType)
      let data = {
        limit: this.limit,
        page: this.page,
        token: this.token,
        type: "99",
        name: this.name,
        status: this.biaozhunType,
      };
      getPolicyList(data).then((rec) => {
        // console.log('打印返回的数据', rec.data)
        this.tableData = rec.data.data;
        this.viewFile(this.tableData[0].url, this.tableData[0].name);
        this.limit = rec.data.per_page;
        this.page = rec.data.current_page;
        this.totalCount = rec.data.total;
        // this.totalPage = rec.totalPage;
        // console.log(rec)
      });
    },
    download(row) {
      console.log("打印行数据", row);
      // window.open(row.url)
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = row.url;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      // setTimeout(() => {
      //   iframe.remove();
      // }, 5 * 60 * 1000);
    },
    del(id) {
      this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        policyDel({ id: id, token: this.token }).then((rec) => {
          console.log(rec);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getList();
        });
      });
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    gotoStep(row) {
      let url = "/project/stepInformation?id=" + row.id + "&active=";
      if (row.status < 3 || row.status == 15 || row.status == 16) {
        url += 0;
      } else if (row.status < 7 || row.status == 17 || row.status == 18) {
        url += 1;
      } else if (row.status < 12) {
        url += 2;
      } else if (row.status < 13) {
        url += 3;
      } else if (row.status < 14) {
        url += 4;
      } else {
        url += 5;
      }
      return url;
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    clearWord() {
      // (this.firstUnit = ""),
      //   (this.proName = ""),
      //   (this.zhidingType = ""),
      //   (this.biaozhunType = ""),
      this.name = "";
      this.biaozhunType = "";
      this.getList();
    },
    // 分页
    handleSizeChange() {
      // this.limit = page;
      // this.getList();
      // console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${page}`)
      this.page = val;
      this.getList();
    },
    //表格多选
    handleSelectionChange(val) {
      console.log("打印选中的值的结果", val);
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped>
/deep/.el-button--primary {
  background-color: #164c90;
  border-color: #164c90;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all 0.3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell > .cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2d2d2d;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell > .cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.rightbtns {
  display: flex;
  justify-content: flex-end;
}

/deep/.jieduan .el-select {
  width: 90%;
}

/deep/.jieduan .el-input__suffix {
  right: 2px;
}

.litop {
  margin: 20px 24px;
}

.wenjian {
  display: flex;
  flex-direction: row;
}

.shangchuan {
  width: 70%;
}

.shwj {
  margin-left: 10px;
  margin-top: -2px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082e2;
}

.linkto > span:hover {
  color: #84b5ec;
}

.litop .litop_title {
  /* display:flex; */
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #eeeeee;
}
</style>
